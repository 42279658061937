import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaCalendarDay,
  FaLaptop,
  FaRegClipboard,
  FaUserTie,
  FaServer,
  FaKeyboard,
  FaPassport,
  FaLock,
} from "react-icons/fa";
import Swiper from "react-id-swiper";
import styled from "styled-components";
import { Autoplay, Pagination } from "swiper";
import Clients from "../components/Clients2";
import Columns from "../components/columns";
import Container from "../components/container";
import Hero from "../components/HeroBanner";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TestimonialCard from "../components/TestimonialCard";
import Tile from "../components/Tile";
import heroImage from "../images/questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import * as styles from "./index.module.css";
import Dots from "../components/dots";
import { Link } from "gatsby";

const params = {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    dynamicBullets: false,
  },
  autoplay: {
    delay: 4000,
  },
};

export const query = graphql`
  {
    heroBg: file(
      relativePath: {
        eq: "questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgLaptopITel: file(relativePath: { eq: "mobilny-program-crm.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
    }
    imgHero: file(
      relativePath: { eq: "synergius-crm-program-crm-dla-firm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
    }
    certyfikat: file(relativePath: { eq: "iso-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    imgSage: file(relativePath: { eq: "integracje/integracja-symfonia.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgSubiekt: file(
      relativePath: { eq: "integracje/integracja-subiekt.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgCDN: file(relativePath: { eq: "integracje/integracja-optima.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgWapro: file(relativePath: { eq: "integracje/integracja-wapro.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    logoRadioSzczecin: file(
      relativePath: { eq: "rszczecin-logo-150x150.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logoLoco: file(relativePath: { eq: "loco-logo-150x150.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logoScapol: file(relativePath: { eq: "scapol-logo-150x150.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    modecom: file(relativePath: { eq: "modecom-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    unilever: file(relativePath: { eq: "kolor-unilever.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    interhandler: file(relativePath: { eq: "jcb-interhandler-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pgnig: file(relativePath: { eq: "pgnig_logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mtp: file(relativePath: { eq: "grupa-mtp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kompania: file(relativePath: { eq: "kompania-piwowarska-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    sab: file(relativePath: { eq: "sab-miller.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    enpol: file(relativePath: { eq: "enpol.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    dedra: file(relativePath: { eq: "kolor-dedra.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mfo: file(relativePath: { eq: "mfo-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    zibi: file(relativePath: { eq: "zibi.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    europart: file(relativePath: { eq: "europart.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    euromasz: file(relativePath: { eq: "euromasz-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    ever: file(relativePath: { eq: "kolor-ever.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    digital: file(relativePath: { eq: "dsd.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kospel: file(relativePath: { eq: "kolor-kospel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    petring: file(relativePath: { eq: "petring.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    aermec: file(relativePath: { eq: "aermec-logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    copy: file(relativePath: { eq: "copy-system.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    metal: file(relativePath: { eq: "metal-fach.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    axium: file(relativePath: { eq: "axium-logo-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    axtel: file(relativePath: { eq: "kolor-axtel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    gourmet: file(relativePath: { eq: "gourmet-foods-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pikselomania: file(relativePath: { eq: "kolor-pikselomania.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mzuri: file(relativePath: { eq: "mzuri.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hydrotank: file(relativePath: { eq: "hydrotank.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    yg: file(relativePath: { eq: "yg1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logo: file(relativePath: { eq: "favicon-new.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    synergius: file(relativePath: { eq: "synergius-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: FULL_WIDTH)
      }
    }
    automico: file(relativePath: { eq: "automicob2b-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: FULL_WIDTH)
      }
    }
    serwis: file(relativePath: { eq: "sp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: FULL_WIDTH)
      }
    }
    schipolska: file(relativePath: { eq: "logo-schipolska.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    tedmars: file(relativePath: { eq: "tedmars.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kon: file(
      relativePath: {
        eq: "questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    dts: file(relativePath: { eq: "logo-dts.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    ravenol: file(relativePath: { eq: "ravenol.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    voltea: file(relativePath: { eq: "voltea-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    autoid: file(relativePath: { eq: "autoid-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mpllogo: file(relativePath: { eq: "mpl-logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    versim: file(relativePath: { eq: "versim-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kmt: file(relativePath: { eq: "kmt-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    euromilk: file(relativePath: { eq: "euromilk-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    prokmet: file(relativePath: { eq: "prokmet-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    azzardo: file(relativePath: { eq: "azzardo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    sgarden: file(relativePath: { eq: "sgarden.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hairtalk: file(relativePath: { eq: "hairtalk.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    signal1: file(relativePath: { eq: "signal1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pion: file(relativePath: { eq: "pion-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    sqm: file(relativePath: { eq: "sqm-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    lcb: file(relativePath: { eq: "lcb.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    frogum: file(relativePath: { eq: "frogum-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    farmtrac: file(relativePath: { eq: "farmtrac.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    wolff: file(relativePath: { eq: "wolff-poligrafia-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, layout: FULL_WIDTH)
      }
    }
    a4: file(relativePath: { eq: "a4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    demps: file(relativePath: { eq: "demps.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    glover: file(relativePath: { eq: "glover.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    gls: file(relativePath: { eq: "gls2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    krosno: file(relativePath: { eq: "krosno.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    filplast: file(relativePath: { eq: "filplast-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    agrosiec: file(relativePath: { eq: "agrosiec-maszyny.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    nespresso: file(relativePath: { eq: "nespresso-klienci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
        lublin: file(relativePath: { eq: "targi-lublin-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            keller: file(relativePath: { eq: "keller-logo.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                mtb: file(relativePath: { eq: "mtb-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                    oknopol: file(relativePath: { eq: "okno-pol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            fagron: file(relativePath: { eq: "fagron-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                loos: file(relativePath: { eq: "logo-loos.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
             dpelogo: file(relativePath: { eq: "dpe-logo-claim.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
             shimpol: file(relativePath: { eq: "shim-pol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
              intermag: file(relativePath: { eq: "intermag-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
              sievert: file(relativePath: { eq: "sievert-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                  splus: file(relativePath: { eq: "s-plus-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                   meprozet: file(relativePath: { eq: "meprozet-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: FULL_WIDTH)
      }
    }
                       enpoll: file(relativePath: { eq: "enpol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
  }
`;

const GreenBanner = styled.div`
  width: 70%;
  padding: 2rem;
  margin-left: 2rem;
  background-color: hsla(96.9, 82.4%, 33.3%, 0.6);
  border-radius: 10px;
  margin-bottom: 10rem;
  align-self: flex-start;
  text-align: left;

  h1 {
    font-size: 48px;
    text-align: left;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 32px;
    text-align: left;
    margin-bottom: 1.5rem;
  }

  p {
    max-width: 70%;
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: 2rem auto;

    p {
      max-width: 100%;
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Producent systemów dla firm - zadbaj o swój biznes"
      description="Questy - Poznański producent oprogramowania dla firm ✅ Rozwiązania dedykowane • Systemy do zarządzania firmą • Systemy CRM • Platformy B2B"
      keywords={["Questy"]}
    />
    <Hero
      heroImage={data.heroBg}
      height="100%"
      imageHeight="calc(100vh - 60px)"
      justifyContent="flex-end"
    >
      <GreenBanner>
        <h1>Twój biznes wygrywa</h1>

        <h2>Dzięki synergii talentu ludzi z naszą technologią</h2>
        <p>
          W dzisiejszych czasach tylko nieliczni mogą przetrwać bez przewagi
          technologicznej. Dlatego rozumiemy swoją rolę w&nbsp;budowaniu sukcesu
          naszego klienta.
        </p>
      </GreenBanner>
    </Hero>

    <Container>
      <Columns>
        <div
          style={{
            padding: 20,
          }}
        >
          <h2 style={{
        paddingBottom: 15,
      }}>Questy – producent rozwiązań dla biznesu </h2>
          <p>
           Jesteśmy polską firmą, która już od 2002 roku 
            <strong> dostarcza efektywne narzędzia informatyczne wspierające rozwój biznesu</strong>.
            Nasze doświadczenie i&nbsp;pasja do technologii
            pozwalają nam tworzyć rozwiązania biznesowe, które przynoszą naszym
            klientom wymierne korzyści.</p><p>
            Wśród naszych klientów znajdują się zarówno duże i rozpoznawalne firmy, 
            jak i mniejsze, dynamicznie rozwijające się przedsiębiorstwa.
            <Link to="/wspierane-obszary/">
              <strong> Poznaj&nbsp;naszą ofertę </strong>
            </Link>już teraz i&nbsp;dołącz do grona zadowolonych klientów!
          </p>
        </div>
        <div className="text-block"  style={{
          paddingTop: 30,
                textAlign: "center",
              }}>
              <div className="mobile-features__image">
          <GatsbyImage
            image={data.logo.childImageSharp.gatsbyImageData}
            style={{
              textAlign: "right",
              maxWidth: 200,
              marginTop: 10,
              marginLeft: 100,
            }}
            alt="Producent oprogramowań dla biznesu"
          />
          </div>
        </div>
      </Columns>
    </Container>

    <Container>
      <h2 style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}> Questy w liczbach</h2>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaCalendarDay size="70px" color="#86BC2A" />}
          title=" Od 2002 roku wspieramy&nbsp;klientów&nbsp;biznesowych"
        />
        <BenefitBlock
          icon={<FaRegClipboard size="70px" color="#86BC2A" />}
          title="Ponad 1&nbsp;000 zrealizowanych&nbsp;projektów"
        />
        <BenefitBlock
          imga={
            <GatsbyImage
              image={data.pikselomania.childImageSharp.gatsbyImageData}
            />
          }
          icon={<FaUserTie size="70px" color="#86BC2A" />}
          title="Ponad 50 pasjonatów&nbsp;technologii&nbsp;w&nbsp;zespole"
        />
        <BenefitBlock
          icon={<FaLaptop size="70px" color="#86BC2A" />}
          title="Więcej niż 100 000 użytkowników&nbsp;systemów"
        />
      </BenefitBlockWrapper>
    </Container>

    <h2 style={{
        paddingTop: 15,
            paddingBottom: 45,
          }}>Nasze rozwiązania</h2>

    <section className={styles.integrations}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.aurabusiness.pl/">
              <GatsbyImage image={data.aura.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.synergiuscrm.pl/">
              <GatsbyImage
                image={data.synergius.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.automicob2b.pl/">
              <GatsbyImage
                image={data.automico.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.serwisplanner.pl/">
              <GatsbyImage
                image={data.serwis.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <p style={{
        paddingBottom: 30,
      }}> </p>

    <section
      style={{
        background: "#f9f9f9",
      }}
    >
      <Container>
        <h2
          style={{
            paddingBottom: 20,
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          Klienci o Questy
        </h2>
        <Swiper {...params}>
                  <div>
            <TestimonialCard
              content="Wdrożenie CRM jest procesem trudnym i mieliśmy świadomość, że nie zawsze kończy się powodzeniem. 
              Dobre zrozumienie potrzeb klienta i profesjonalne wsparcie firmy Questy na każdym poziomie wdrożenia 
              spowodowało, że odnieśliśmy sukces. Dzisiaj z dumą mogę powiedzieć, że obserwujemy wzrost efektywności 
              pracy zespołu sprzedażowego, a o to właśnie chodziło. Z czystym sumieniem mogę polecić firmę Questy 
              oraz ich rozwiązanie, Aura Business CRM."
              logo={
                <GatsbyImage
                  image={data.mtp.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business w Grupie MTP"
                />
              }
              name="Janusz Mazurczak"
              company="Grupa MTP"
            />
          </div>
                            <div>
            <TestimonialCard
              content="Z firmą Questy współpracujemy niemal od początku naszej działalności. Narzędzie, z którego za jej 
              pośrednictwem korzystamy jest dla nas podstawowym środowiskiem pracy. Ma ono tę przewagę w stosunku do produktów 
              konkurencyjnych, że jest szybko i sprawnie dostosowywane do naszych nowych wyzwań i obejmuje wyłącznie ściśle 
              określony zestaw funkcji niezbędnych w codziennej pracy. Bardzo cenimy sobie bezpośrednią i sprawną formę 
              kontaktu z dedykowanym opiekunem Questy i mamy nadzieję na dalszą owocną współpracę."
              logo={
                <GatsbyImage
                  image={data.dpelogo.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business w Domu Pełnym Energii"
                />
              }
              name="Stanisław Górski"
              company="Dom Pełen Energii"
            />
          </div>
                    <div>
            <TestimonialCard
              content="Polecamy zarówno system Aura Business, jak i samą współpracę. 
              Wysoki profesjonalizm, biznesowe podejście, elastyczność wobec wymagań klienta 
              utwierdzają nas w przekonaniu, że jest to jeden z najlepszych producentów 
              systemów informatycznych na polskim rynku."
              logo={
                <GatsbyImage
                  image={data.interhandler.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business w firmie Interhandler"
                />
              }
              name="Maciej Piątkowski"
              company="Interhandler Sp. z o.o."
            />
          </div>

                            <div>
            <TestimonialCard
              content="Jesteśmy bardzo zadowoleni ze współpracy z firmą Questy. Zaproponowane rozwiązania systemu 
              Aura Bussiness zostały płynnie wprowadzone do naszej firmy i dzięki temu możemy teraz cieszyć się 
              poprawą organizacji pracy we wszystkich działach. Oczywiście polecamy."
              logo={
                <GatsbyImage
                  image={data.loos.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business w Loos Centrum Sp. z o.o. Bosch Group"
                />
              }
              name="Julia Domagała"
              company="Loos Centrum Sp. z o.o. Bosch Group"
            />
          </div>
          <div>
            <TestimonialCard
              content="Ze względu na profesjonalizm i zaangażowanie ze strony firmy Questy 
              proces wdrożenia przebiegał bardzo sprawnie. Jesteśmy pod wrażeniem ogromu 
              wiedzy oraz zaangażowania pracowników, aby system B2B był w pełni dopasowany 
              zarówno do potrzeb naszych jak i naszych klientów."
              logo={
                <GatsbyImage
                  image={data.metal.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Platforma B2B w Metal-Fach"
                />
              }
              name="Marta Marculewicz"
              company="Metal-Fach Technika Grzewcza"
            />
          </div>
          <div>
            <TestimonialCard
              content="Jako firma skupiamy się na obsłudze sprzedaży szerokiego wachlarza produktów do Klientów z kanału B2B, w związku z tym szukaliśmy narzędzia usprawniającego pracę i wymianę danych. Posiadając system ERP Exact, który nie jest popularnym systemem w firmach o podobnym profilu działania, mieliśmy duży problem ze znalezieniem firmy dostarczającej oprogramowanie typu B2B z możliwością integracji baz danych. Pomimo naszych wcześniejszych obaw o możliwość integracji, proces ten przeszedł bezproblemowo, umożliwiając nam korzystanie z nowoczesnych funkcji dostarczanego przez Questy oprogramowania."
              logo={
                <GatsbyImage
                  image={data.dedra.childImageSharp.gatsbyImageData}
                  style={{
                    width: "105px",
                  }}
                  alt="Aura Business - platforma B2B w firmie Dedra Sp. z o.o."
                />
              }
              name="Marcin Kruszewski"
              company="Dedra Sp. z o.o."
            />
          </div>
                    <div>
            <TestimonialCard
              content="Z czystym sumieniem mogę powiedzieć, że proces wdrażania poszczególnych modułów do naszego 
              Aura CRM przebiegł pomyślnie. Wiele razy firma Questy miała z nami pod górkę, ale Panowie, którzy opiekowali 
              się naszym systemem mają ogromną wiedzę, cierpliwość i są dobrze zorganizowani. Liczymy, że dalsza 
              współpraca również będzie szła gładko."
              logo={
                <GatsbyImage
                  image={data.dts.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business w DTS SYSTEM"
                />
              }
              name="Maciej Stram"
              company="DTS SYSTEM Sp. z o.o."
            />
          </div>
          <div>
            <TestimonialCard
              content="System Aura Business Service Management Suite pozwolił firmie Enpol na efektywne zarządzanie zleceniami 
              realizowanymi dla firmy Unilever. Najważniejszym efektem wdrożenia jest umożliwienie precyzyjnej obsługi serwisu oraz 
              usprawnienie komunikacji pomiędzy firmami i ich podwykonawcami przy pomocy wygodnego w obsłudze systemu informatycznego."
              logo={
                <GatsbyImage
                  image={data.enpol.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business wspomaga zarządzanie zleceniami w Enpol"
                />
              }
              name=""
              company="Enpol"
            />
          </div>
          <div>
            <TestimonialCard
              content="W związku z rozwojem firmy zarząd firmy postanowił wdrożyć system informatyczny usprawniający kluczowe procesy 
              biznesowe w firmie Schipolska. Na swojego dostawcę firma Schipolska wybrała poznańskiego producenta systemu Aura Business – firmę 
              Questy. Wdrożony system Aura Business pełni istotną rolę w codziennej pracy firmy Schipolska i jesteśmy zadowoleni ze współpracy z 
              firmą Questy. Polecam system Aura Business oraz firmę Questy."
              logo={
                <GatsbyImage
                  image={data.schipolska.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business wspomaga zarządzanie w firmie Schiipolska"
                />
              }
              name="Damian Semba"
              company="Biuro Techniczno-Handlowe Schipolska Sp. z o.o."
            />
          </div>
          <div>
            <TestimonialCard
              content="Współpracę z firmą Questy rozpoczęliśmy od wdrożenia systemu Aura B2B, którego firma Questy jest producentem. 
              Prace wdrożeniowe trwały kilka miesięcy i od samego początku charakteryzowały się dużym zaangażowaniem ze strony pracowników 
              Questy. W trakcie prac konsultanci wdrożeniowi wykazali się rozległą wiedzą biznesową oraz fachowym doradztwem. Firma Questy 
              zaproponowała nam elastyczny model wdrożenia systemu aura B2B, który nie zakłócał bieżących prac firmy Tedmars i 
              nie kolidował z realizowaniem założonych celów przedsiębiorstwa."
              logo={
                <GatsbyImage
                  image={data.tedmars.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business - platforma B2B w firmie Tedmars"
                />
              }
              name="Zbigniew Zieliński"
              company="P.H.U. Tedmars"
            />
          </div>
        </Swiper>
      </Container>
    </section>

    <h2 style={{
          paddingTop: 45,
          paddingBottom: 25,
        }}>Zaufali nam, między innymi:</h2>
    <center></center>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.unilever.pl/">
              <GatsbyImage
                image={data.unilever.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.mtp.pl/">
              <center><GatsbyImage image={data.mtp.childImageSharp.gatsbyImageData} 
              style={{
                    width: "175px",
                  }}/> 
                  </center>
            </a>
          </div>
                    <div>
            <a href="https://gls-group.eu/PL/pl/home">
              <GatsbyImage image={data.gls.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://filplast.pl/">
            <GatsbyImage
                image={data.filplast.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://dompelenenergii.pl/">
              <center><GatsbyImage image={data.dpelogo.childImageSharp.gatsbyImageData} 
             style={{
                    width: "185px",
                  }} /></center>
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.interhandler.pl/">
               <center><GatsbyImage
                image={data.interhandler.childImageSharp.gatsbyImageData}
              style={{
                    width: "190px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="http://www.kp.pl/">
              <GatsbyImage
                image={data.kompania.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.kospel.pl/">
              <GatsbyImage
                image={data.kospel.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.metalfach.com.pl/">
              <GatsbyImage image={data.metal.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.dedra.pl/">
              <GatsbyImage image={data.dedra.childImageSharp.gatsbyImageData} />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="https://modecom.com/pl/">
              <GatsbyImage
                image={data.modecom.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.yg-1.pl/">
             <center> <GatsbyImage image={data.yg.childImageSharp.gatsbyImageData} 
              style={{
                    width: "180px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="https://www.agro-siec.pl/">
              <GatsbyImage
                image={data.agrosiec.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.zibi.pl/">
              <GatsbyImage image={data.zibi.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
          <a href="https://targi.lublin.pl/pl/">
              <GatsbyImage
                image={data.lublin.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          
        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.pgnig.pl/">
              <GatsbyImage image={data.pgnig.childImageSharp.gatsbyImageData} />
            </a>
          </div>
           <div>
            <a href="https://www.nespresso.com/pl/pl/">
              <GatsbyImage
                image={data.nespresso.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
           <div>
            <a href="https://www.shim-pol.pl/">
              <center><GatsbyImage
                image={data.shimpol.childImageSharp.gatsbyImageData}
                style={{
                    width: "160px",
                  }}
              /></center>
            </a>
          </div>
          <div>
            <a href="https://azzardo.com.pl/">
              <GatsbyImage
                image={data.azzardo.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://okno-pol.pl/">
              <GatsbyImage
                image={data.oknopol.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
          <a href="http://www.mfo.pl/">
              <GatsbyImage image={data.mfo.childImageSharp.gatsbyImageData} 
              style={{
                    width: "180px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="https://petring.com.pl/pl/">
              <GatsbyImage image={data.petring.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.aermec.com/">
              <GatsbyImage
                image={data.aermec.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://intermag.pl/">
              <GatsbyImage
                image={data.intermag.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://mplpower.pl/">
              <center><GatsbyImage image={data.mpllogo.childImageSharp.gatsbyImageData} 
              style={{
                    width: "170px",
                  }}/></center>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.dsd.com.pl/">
              <GatsbyImage
                image={data.digital.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://euromilk.pl/pl/">
              <GatsbyImage
                image={data.euromilk.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://voltea.pl/">
              <GatsbyImage
                image={data.voltea.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://krosno.com.pl/">
              <center><GatsbyImage
                image={data.krosno.childImageSharp.gatsbyImageData}
                style={{
                    width: "190px",
                  }}
              /></center>
            </a>
          </div>
          <div>
            <a href="https://www.dilmah.pl">
              <center><GatsbyImage image={data.gourmet.childImageSharp.gatsbyImageData} 
              style={{
                    width: "170px",
                  }}/></center>
            </a>
          </div>
        
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://www.versim.pl/">
              <GatsbyImage
                image={data.versim.childImageSharp.gatsbyImageData}
              style={{
                    width: "185px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="http://kmt.com.pl/pl/">
              <GatsbyImage image={data.kmt.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://www.frogum.com/pl/">
              <GatsbyImage
                image={data.frogum.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://www.farmtrac.pl/">
              <GatsbyImage
                image={data.farmtrac.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://wolfftrading.pl/">
              <GatsbyImage image={data.wolff.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://sqm.eu/">
             <center> <GatsbyImage image={data.sqm.childImageSharp.gatsbyImageData} 
              style={{
                    width: "150px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="https://www.hydrotank.eu">
              <GatsbyImage
                image={data.hydrotank.childImageSharp.gatsbyImageData}
                style={{
                    width: "180px",
                  }}
              />
            </a>
          </div>
          <div>
            <a href="https://www.sievert.pl/">
              <GatsbyImage image={data.sievert.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.glover.pl/">
              <GatsbyImage
                image={data.glover.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://pi-on.pl">
              <GatsbyImage
                image={data.pion.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://e-keller.pl/">
              <GatsbyImage
                image={data.keller.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://mtb-polska.pl/">
              <center><GatsbyImage image={data.mtb.childImageSharp.gatsbyImageData} 
               style={{
                    width: "180px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="https://pl.fagron.com/">
              <GatsbyImage image={data.fagron.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://euromasz.pl/">
               <center><GatsbyImage image={data.euromasz.childImageSharp.gatsbyImageData} 
               style={{
                    width: "180px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="https://s-plus.pl">
             <center> <GatsbyImage image={data.splus.childImageSharp.gatsbyImageData} 
               style={{
                    width: "110px",
                  }}/></center>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.enpol.com.pl/">
             <center> <GatsbyImage image={data.enpoll.childImageSharp.gatsbyImageData} 
              style={{
                    width: "110px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="http://www.s-garden.pl/">
              <GatsbyImage
                image={data.sgarden.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://lcb.leszno.pl/">
             <center> <GatsbyImage image={data.lcb.childImageSharp.gatsbyImageData} 
              style={{
                    width: "150px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="http://www.europart.net/">
              <GatsbyImage
                image={data.europart.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.copysystem.pl/">
              <center><GatsbyImage image={data.copy.childImageSharp.gatsbyImageData} 
              style={{
                    width: "160px",
                  }}/> </center>
            </a>
          </div>

        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.prokmet.com/">
              <GatsbyImage
                image={data.prokmet.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
         <div>
            <a href="http://a4polska.pl/">
              <center><GatsbyImage image={data.a4.childImageSharp.gatsbyImageData} 
              style={{
                    width: "160px",
                  }}/></center>
            </a>
          </div> 
           <div>
            <a href="https://meprozet.com.pl/pl">
              <GatsbyImage image={data.meprozet.childImageSharp.gatsbyImageData} 
              style={{
                    width: "190px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="http://www.pikselomania.pl/">
              <GatsbyImage
                image={data.pikselomania.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>

          <div>
            <a href="http://www.demps.com.pl/">
              <GatsbyImage image={data.demps.childImageSharp.gatsbyImageData}
              style={{
                    width: "170px",
                  }} />
            </a>
          </div>

          
        </div>
      </div>
    </section>

    <section
      style={{
        padding: 40,
      }}
    >
      <div className="container container--text">
        <h2
          style={{
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          Bezpieczeństwo naszych rozwiązań
        </h2>

        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaPassport size="70px" />}
            title="Certyfikowane metody zarządzania przepływem informacji"
          ></BenefitBlock>
          <BenefitBlock
            icon={<FaKeyboard size="70px" />}
            title="Narzędzia do zarządzania uprawnieniami według&nbsp;profili użytkowników"
          ></BenefitBlock>
          <BenefitBlock
            icon={<FaServer size="70px" />}
            title="Stabilny hosting u najlepszych dostawców powierzchni serwerowych"
          ></BenefitBlock>
        </BenefitBlockWrapper>
      </div>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              W Questy<strong> spełniamy międzynarodowe normy bezpieczeństwa</strong>{" "}
              poparte certyfikatem ISO/IEC 27001.
            </p>
            <p>
              <ul>
                <li>
                  Realizujemy prawne wymagania z zakresu ochrony informacji
                </li>
                <li>
                  Niwelujemy ryzyko pojawienia się problemów z dostępem do
                  danych{" "}
                </li>
                <li>
                  Wdrożyliśmy scenariusze działania, które pozwalają natychmiast
                  reagować na potencjalne incydenty
                </li>
              </ul>
            </p>
          </div>

          <div className="text-block">
            <center>
              <div className="mobile-features__image">
                <GatsbyImage
                  image={data.certyfikat.childImageSharp.gatsbyImageData}
                  style={{
                    width: "300px",
                    margin: "40px",
                  }}
                />
              </div>
            </center>
          </div>
        </div>
      </Container>
    </section>
    
  </Layout>
);

export default IndexPage;
