import React from "react";
import styled from "styled-components";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  text-align: center;

  p {
    margin: 0;
  }
`;

const Content = styled.div`
  display: flex;
  text-align: center;

  p {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.5;
    font-style: italic;
  }

  svg {
    color: #ddd;
  }

  @media screen and (max-width: 650px) {
    p {
      font-size: 1.2rem;
    }
  }
`;

const Logo = styled.div`
  border-radius: 0%;
  overflow: hidden;
  margin: 20px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const TestimonialCard = ({ logo, name, company, content }) => {
  return (
    <Wrapper>
      <Content>
        <FaQuoteLeft size={65} />
        <p>{content}</p>
        <FaQuoteRight size={65} />
      </Content>
      <Logo>{logo}</Logo>
      <div>
        <p>
          <strong>{name}</strong>
        </p>
        <p>{company}</p>
      </div>
    </Wrapper>
  );
};

export default TestimonialCard;
